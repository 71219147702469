import './_app.scss';
import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Navigation from 'Navigation';
import Pages from 'Pages/Pages';


function App() {
  return (
    <BrowserRouter>
    <Routes>
      <Route path="/" element={<Navigation/>}>
        <Route index element={<Pages.Home/>} />
        <Route path="*" element={<Pages.NotFound />} />
      </Route>
    </Routes>
  </BrowserRouter>
  );
}

export default App;
