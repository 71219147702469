import { useEffect, useRef, useState } from 'react';
import './_notFound.scss';

function Home() {
    const [image, setImage] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const isMounted = useRef(false);

    useEffect(() => {
        isMounted.current = true;
        fetch('https://shibe.online/api/shibes')
        .then(response => {
            return response.json();
            // handle the response
        })
        .catch(error => {
            console.log(error);
            // handle the error
        }).then(([randomImage]) => {
            if (!isMounted.current) {
                return;
            }

            setImage(randomImage)
        })
        .finally(() => {
            if (!isMounted.current) {
                return;
            }
            setIsLoading(false);
        })

        return () => { isMounted.current = false }
    }, []);

    return (
    <div className='not-found'>
        <span>
            Page not found. <br/>
            Here is a random image of a Shiba Inu
        </span>
        <div>
            {!!isLoading && '...Loading'}
            {!isLoading && <img src={image} alt='random' height={200} width={200} />}
        </div>

    </div>
    );
}

export default Home;
