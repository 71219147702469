import './_home.scss';

function Home() {
  return (
    <>
      ...
    </>
  );
}

export default Home;
