
import React from "react";
import './_app.scss';

import { Outlet, Link, useLocation } from "react-router-dom";

function Navigation() {
  const {pathname: location} = useLocation();

  return (
    <>
      <nav className="nav">
        <ul className="nav__list">
          <span className='logo'>[X]</span>
          <nav className="nav__list-items">
            <li className="nav__list-item">
              <Link className={`nav__list-item__link ${location === '/' ? '-is-active' : ''}`} to="/">Home</Link>
            </li>
          </nav>
        </ul>
      </nav>

      <Outlet />
    </>
  );
}

export default Navigation;
